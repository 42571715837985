import React, { useEffect, useState, useContext, useMemo } from 'react';
import { httpsCallable, getFunctions } from 'firebase/functions';

import moment from 'moment';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Typography, Button, Chip } from '@mui/material';

import PageCards from '../ui/PageCards';
import AlertContext from '../ui/AlertContext';
import MRT_TABLE from '../ui/MRT_TABLE';

export default function VacationAdmin() {
	const alertCtx = useContext(AlertContext);
	const [users, setUsers] = useState(null);
	const [loading, setLoading] = useState(true);
	const [removing, setRemoving] = useState(false);

	// Fetch all users with outside US access
	useEffect(() => {
		const fetchVacationRequests = async () => {
			setLoading(true);
			const functions = getFunctions();
			const Outside_US_Get_Users = httpsCallable(functions, 'Outside_US_Get_Users');
			try {
				const result = await Outside_US_Get_Users();
				console.log('result:', result);
				if (result.data.success) {
					const data = result.data.data;
					console.log('data:', data);
					const sortedUsers = data.sort((a, b) => a.Preferred_Name.localeCompare(b.Preferred_Name));
					setUsers(sortedUsers);
				} else {
					setUsers([]);
					console.error('Error fetching vacation requests:', result.data.message);
					alertCtx.setMessage(result.data.message);
					alertCtx.setSeverity('error');
					alertCtx.setTitle('Error');
					alertCtx.setActive(true);
				}
			} catch (err) {
				setUsers([]);
				console.error('Error fetching vacation requests:', err);
				alertCtx.setMessage(err.message);
				alertCtx.setSeverity('error');
				alertCtx.setTitle('Error');
				alertCtx.setActive(true);
			}
			setLoading(false);
		};

		if (!users) fetchVacationRequests();
	}, []);

	// Remove outside US access for a user
	const handleRemoveAccess = async (EPT_Email) => {
		setRemoving(true);
		const functions = getFunctions();
		const Outside_US_Remove_User_Access = httpsCallable(functions, 'Outside_US_Remove_User_Access');
		try {
			const response = await Outside_US_Remove_User_Access({ email: EPT_Email });
			console.log('response:', response);
			if (response.data.success) {
				const remainingUsers = users.filter((user) => user.EPT_Email !== EPT_Email);
				setUsers(remainingUsers);
			} else {
				console.error('Error removing outside US access:', response.data.message);
				alertCtx.setMessage(response.data.message);
				alertCtx.setSeverity('error');
				alertCtx.setTitle('Error');
				alertCtx.setActive(true);
			}
		} catch (err) {
			console.error('Error removing outside US access:', err);
			alertCtx.setMessage(err.message);
			alertCtx.setSeverity('error');
			alertCtx.setTitle('Error');
			alertCtx.setActive(true);
		}
		setRemoving(false);
	};

	// Table columns
	const columns = useMemo(
		() => [
			{
				id: 'name',
				accessorFn: (row) => `${row.Preferred_Name} ${row.Last_Name}`,
				header: 'Name',
			},
			{
				accessorFn: (row) =>
					row?.Outside_US_Access?.StartDate._seconds
						? moment(row.Outside_US_Access.StartDate._seconds * 1000).format('MM/DD/YYYY')
						: null,
				header: 'Start Date',
			},
			{
				accessorFn: (row) =>
					row?.Outside_US_Access?.EndDate._seconds
						? moment(row.Outside_US_Access.EndDate._seconds * 1000).format('MM/DD/YYYY')
						: null,
				header: 'End Date',
			},
			{
				accessorFn: (row) =>
					row?.Outside_US_Access?.Active ? <Chip label='True' color='success' variant='outlined' /> : null,
				header: 'Active',
			},
			{
				header: 'Remove Access',
				accessorFn: (row) => (
					<Button
						variant='contained'
						color='secondary'
						disabled={removing}
						onClick={() => handleRemoveAccess(row.EPT_Email)}
					>
						Remove Outside US Access
					</Button>
				),
			},
		],
		[users, removing]
	);

	return (
		<Box sx={{ width: '100%' }}>
			<Grid container spacing={1}>
				<Grid xs={12}>
					<PageCards>
						<Typography variant='h3' color='primary' mb={2} textAlign='center'>
							Vacation Management
						</Typography>
						<Typography mb={2} textAlign='center'>
							Note: Access will remain for 1 full day after the user's Last Day to allow for differences in timezones
							and travel delays.
						</Typography>

						<MRT_TABLE
							data={users || []}
							columns={columns}
							loading={loading || removing}
							sortBy={[{ Id: 'name', Direction: 'asc', TrueFalse: true }]}
						/>
					</PageCards>
				</Grid>
			</Grid>
		</Box>
	);
}
