import React, { useEffect, useState, Fragment, useContext } from 'react';
import { Stack, TextField, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import ArrowCircleUpSharpIcon from '@mui/icons-material/ArrowCircleUpSharp';
import ArrowCircleDownSharpIcon from '@mui/icons-material/ArrowCircleDownSharp';

import DirectoryCard from './DirectoryCard';
import LoadingSpinner from '../ui/LoadingSpinner';
import CustomClaimsContext from '../auth/CustomClaimsContext';

export default function DirectoryList(props) {
	const [filteredList, setFilteredList] = useState(null);
	const [keyword, setKeyword] = useState('');
	const [enteredName, setEnteredName] = useState(''); // the value of the search field
	const claimsCtx = useContext(CustomClaimsContext);

	//set keyword
	const handleKeyword = (event) => {
		setKeyword(event.target.value);
		setEnteredName(event.target.value);
	};

	//filters list
	useEffect(() => {
		if (props.list) {
			if (keyword !== '') {
				const results = props.list.filter((member) => {
					// Use the toLowerCase() method to make it case-insensitive
					return (
						(member.Preferred_Name && member.Preferred_Name.toLowerCase().includes(keyword.toLowerCase())) ||
						(member.EPT_Email && member.EPT_Email.toLowerCase().includes(keyword.toLowerCase())) ||
						(member.Last_Name && member.Last_Name.toLowerCase().includes(keyword.toLowerCase())) ||
						(member.Member_Type && member.Member_Type.toLowerCase().includes(keyword.toLowerCase())) ||
						(member.Positions &&
							member.Positions.some((position) => position.toLowerCase().includes(keyword.toLowerCase()))) ||
						(member.Office_Phone && member.Office_Phone.includes(keyword.toLowerCase())) ||
						(member.Mobile_Phone && member.Mobile_Phone.includes(keyword.toLowerCase()))
					);
				});
				setFilteredList(results);
			} else {
				// If the text field is empty, show all providers
				setFilteredList(props.list);
			}
		} else {
			setFilteredList(null);
		}
	}, [props.list, keyword]);

	//passed member from MemberCard passed to here, passes member up to Directory
	const handleSelection = (email) => {
		// console.log(email);
		props.onMemberSelection(email);
	};

	//handle order by change
	const handleOrderByChange = (e) => {
		props.onOrderByChange(e.target.value);
	};

	//handle sort order change
	const handleSortOrderChange = (e) => {
		props.onSortOrderChange(e.target.value);
	};

	//handles member type change
	const handleMemberTypeChange = (e) => {
		props.onMemberTypeChange(e.target.value);
	};

	return (
		<Fragment>
			<Stack>
				<Typography variant='h4' color={'primary'} textAlign='center'>
					Members
				</Typography>
				{/* search email account */}
				{props.orderBy === 'Email' && (
					<TextField
						margin='dense'
						id='search'
						label='Search'
						fullWidth
						variant='outlined'
						color='secondary'
						value={enteredName}
						onChange={handleKeyword}
						placeholder='Type to search...'
						autoFocus
						onKeyUp={(e) => {
							if (e.key === 'Escape') {
								const event = {
									target: {
										value: '',
									},
								};
								handleKeyword(event);
							}
						}}
					/>
				)}

				{/* search by name */}
				{props.orderBy !== 'Email' && (
					<TextField
						margin='dense'
						id='search'
						label='Search'
						fullWidth
						variant='outlined'
						color='secondary'
						value={enteredName}
						onChange={handleKeyword}
						placeholder='Type to search...'
						autoFocus
						onKeyUp={(e) => {
							if (e.key === 'Escape') {
								const event = {
									target: {
										value: '',
									},
								};
								handleKeyword(event);
							}
						}}
					/>
				)}

				{/* sorts the directory list */}
				<Stack direction='row' margin='dense' mt={1} mb={1} spacing={1}>
					<FormControl sx={{ width: '75%' }} size='small'>
						<InputLabel id='orderByLabel'>Sort</InputLabel>
						<Select
							labelId='orderByLabel'
							id='orderBy'
							value={props.orderBy}
							label='Sort'
							onChange={handleOrderByChange}
						>
							<MenuItem value={'Email'}>Email</MenuItem>
							<MenuItem value={'First_Name'}>First Name</MenuItem>
							<MenuItem value={'Last_Name'}>Last Name</MenuItem>
						</Select>
					</FormControl>

					<FormControl sx={{ width: '25%' }} size='small'>
						<Select id='sortBy' value={props.sortOrder} onChange={handleSortOrderChange}>
							<MenuItem value={'ASCENDING'}>
								<ArrowCircleUpSharpIcon fontSize='small' />
							</MenuItem>
							<MenuItem value={'DESCENDING'}>
								<ArrowCircleDownSharpIcon fontSize='small' />
							</MenuItem>
						</Select>
					</FormControl>
				</Stack>

				{/* filters the directory list */}
				<Stack mb={1}>
					<FormControl size='small'>
						<InputLabel id='filterLabel'>Filter</InputLabel>
						<Select
							labelId='filterLabel'
							id='memberType'
							value={props.memberType}
							label='Filter'
							onChange={handleMemberTypeChange}
						>
							<MenuItem value={'ALL'}>All</MenuItem>
							<MenuItem value={'APP'}>APP</MenuItem>
							<MenuItem value={'OFFICE'}>Office</MenuItem>
							<MenuItem value={'OTHER'}>Other</MenuItem>
							<MenuItem value={'PHYSICIAN'}>Physician</MenuItem>
							<MenuItem value={'RESIDENT'}>Resident</MenuItem>
							<MenuItem value={'UNCLASSIFIED'}>Unclassified</MenuItem>
						</Select>
					</FormControl>
				</Stack>

				{/* view unsuspended members, suspended members, or deleted members */}
				{claimsCtx?.claims?.admin && (
					<Stack mb={1} mt={1}>
						<FormControl size='small'>
							<InputLabel id='selection'>User Status</InputLabel>
							<Select
								labelId='selection'
								id='selection'
								value={props.selection}
								label='User Status'
								onChange={(e, newValue) => {
									props.onSelectionChange(newValue.props.value);
								}}
							>
								<MenuItem value={'Active'}>Active</MenuItem>
								<MenuItem value={'Suspended'}>Suspended</MenuItem>
								<MenuItem value={'Deleted'}>Deleted</MenuItem>
							</Select>
						</FormControl>
					</Stack>
				)}

				{/* if loading return loader */}
				{props.isLoading && <LoadingSpinner />}

				{/* if not loading return this */}
				{!props.isLoading &&
					filteredList?.map((member, key) => (
						<DirectoryCard key={key} member={member} onHandleSelection={handleSelection} orderBy={props.orderBy} />
					))}

				{/* if no members found */}
				{!props.isLoading && filteredList?.length === 0 && (
					<Typography variant='h6' color={'primary'} textAlign='center'>
						No members found
					</Typography>
				)}
			</Stack>
		</Fragment>
	);
}
