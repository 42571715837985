import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import VCard from 'vcard-creator';

import Grid from '@mui/material/Unstable_Grid2';
import { Button, Container, Box, Paper, Stack } from '@mui/material';
import ContactPhoneRoundedIcon from '@mui/icons-material/ContactPhoneRounded';

import LoadingSpiner from '../components/ui/LoadingSpinner';
import Tooltip from '../components/ui/Tooltip';

const eptLogo =
	'https://logos.ept911.com/static/media/EPT%20Full%20Logo%20(500%20x%20131%20px).62b9948073dcbcd206d8.png';

function People() {
	const [loading, setLoading] = useState(true);
	const [info, setInfo] = useState(null);
	const { PublicID } = useParams();

	//gets the info associated with the PublicID
	useEffect(() => {
		const callGetPeopleInfo = async () => {
			const functions = getFunctions();
			const Directory_Get_Data_For_Public_ID_Link = httpsCallable(functions, 'Directory_Get_Data_For_Public_ID_Link');
			await Directory_Get_Data_For_Public_ID_Link({ PublicID: PublicID }).then((result) => {
				if (result) {
					// console.log(result.data);
					setInfo(result.data);
				}
			});
		};

		if (!info) callGetPeopleInfo();
	}, []);

	//once info is obtained, stop loading
	useEffect(() => {
		if (info) setLoading(false);
	}, [info]);

	const create_vCard = () => {
		// Define a new vCard
		const vCard = new VCard();

		// Some variables
		const lastname = info.data.lastName;
		const firstname = info.data.firstName;
		const additional = '';
		const prefix = '';
		const suffix = info.data.credentials;

		vCard
			.addName(lastname, firstname, additional, prefix, suffix)
			.addCompany('EPT')
			.addAddress('', info.data.address, '', '', '', '', '', 'EPT Office')
			.addURL(info.data.webAddress, 'WORK');

		if (info.data.email) vCard.addEmail(info.data.email, 'Work');

		if (info.data.phone_mobile) {
			vCard
				.addPhoneNumber(info.data.phone_mobile, 'PREF;Mobile')
				.addPhoneNumber(info.data.phone_ept, 'Main')
				.addPhoneNumber(info.data.phone_office, 'Office')
				.addPhoneNumber(info.data.fax, 'WORK;FAX');
		} else if (info.data.phone_office) {
			vCard
				.addPhoneNumber(info.data.phone_ept, 'Main')
				.addPhoneNumber(info.data.phone_office, 'PREF;Office')
				.addPhoneNumber(info.data.fax, 'WORK;FAX');
		} else {
			vCard.addPhoneNumber(info.data.phone_ept, 'PREF;Main').addPhoneNumber(info.data.fax, 'WORK;FAX');
		}

		//add positions to vCard
		info.data.positions &&
			info.data.positions.forEach((position) => {
				vCard.addJobtitle(position);
			});

		//
		if (info.data.thumbnail) {
			let link = info.data.thumbnail.slice(0, info.data.thumbnail.length - 5);
			vCard.addPhotoURL(link);
			// const fetchImage = async () => {
			// 	try {
			// let image;
			// const response = await fetch(link, { mode: 'no-cors' });
			// const blob = await response.blob();
			// const reader = new FileReader();
			// reader.onloadend = () => {
			// 	const base64data = reader.result;
			// 	image = base64data;
			// };
			// reader.readAsDataURL(blob);
			// vCard.addPhoto(image, 'JPEG');
			// } catch (error) {
			// 	console.error('Error fetching image:', error);
			// }
			// };

			// fetchImage();
		}

		// console.log(vCard.toString());

		// Generate the vCard content
		const vCardContent = vCard.toString();

		// Create a Blob with the vCard content
		const blob = new Blob([vCardContent], { type: 'text/vcard' });

		// Create a temporary URL for the Blob
		const blobUrl = URL.createObjectURL(blob);

		// Create a link element
		const link = document.createElement('a');
		link.href = blobUrl;
		link.download = `${info.data.firstName} ${info.data.lastName}.vcf`; // Set the desired file name for the download

		// Programmatically click the link to trigger the download
		link.click();

		// Clean up by revoking the temporary URL
		URL.revokeObjectURL(blobUrl);
	};

	return (
		<Grid container spacing={1}>
			<Grid xs={12} sx={{ display: 'block', justifyContent: 'center' }}>
				<Container>
					<Paper
						sx={{
							margin: 'auto',
							padding: '1rem',
							maxWidth: { xs: 300, sm: 400 },
							backgroundColor: '#bfcfe8',
							display: 'flex',
							justifyContent: 'center',
							borderRadius: '16px',
						}}
					>
						<Stack spacing={1}>
							{/* loading spinner */}
							{loading && <LoadingSpiner />}

							{/* error message */}
							{!loading && info?.code !== 200 && (
								<Box sx={{ display: 'flex', justifyContent: 'center' }}>{info.message}</Box>
							)}

							{/* user info */}
							{!loading && info.code === 200 && (
								<Box sx={{ display: 'flex', justifyContent: 'center' }}>
									<div style={{ display: 'block', justifyContent: 'center', maxWidth: '375px' }}>
										<div style={{ display: 'flex', justifyContent: 'left' }}>
											{/* user image */}
											{info.data.Thumbnail && (
												<img
													style={{
														border: 'none',
														borderRadius: '90px',
														maxHeight: '7.5rem',
														marginTop: 'auto',
														marginBottom: 'auto',
														marginRight: '0.5rem',
													}}
													src={info.data.Thumbnail}
													alt=''
													referrerPolicy='no-referrer'
												/>
											)}

											{/* inner container */}
											<div
												style={{ display: 'block', justifyContent: 'center', marginTop: 'auto', marginBottom: 'auto' }}
											>
												{/* name */}
												<div style={{ fontWeight: 'bold', color: '#4472c4', fontSize: '0.85rem' }}>
													{info.data.Preferred_Name + ' ' + info.data.Last_Name}
													{info.data.Credentials && ', ' + info.data.Credentials}
												</div>

												{/* positions */}
												{info.data.Positions &&
													info.data.Positions.map((position, key) => (
														<div key={key} style={{ fontWeight: 'bold', color: 'black', fontSize: '0.75rem' }}>
															{position}
														</div>
													))}

												{/* mobile phone */}
												{info.data.Mobile_Phone && (
													<div style={{ display: 'flex', fontSize: '0.7rem' }}>
														<div>
															Mobile: <a href={`tel:${info.data.Mobile_Phone}`}>{info.data.Mobile_Phone}</a>
														</div>
													</div>
												)}

												{/* ept office phone */}
												{info.data.Office_Phone && (
													<div style={{ display: 'flex', fontSize: '0.7rem' }}>
														<div>
															Office: <a href={`tel:${info.data.Office_Phone}`}>{info.data.Office_Phone}</a>
														</div>
													</div>
												)}

												{/* ept main phone */}
												{info.data.EPT_Phone && (
													<div style={{ display: 'flex', fontSize: '0.7rem' }}>
														<div>
															Main: <a href={`tel:${info.data.EPT_Phone}`}>{info.data.EPT_Phone}</a>
														</div>
													</div>
												)}

												{/* fax */}
												{info.data.Fax && (
													<div style={{ display: 'flex', fontSize: '0.7rem' }}>
														<div>Fax: {info.data.Fax}</div>
													</div>
												)}

												{/* ept email link */}
												{info.data.EPT_Email && (
													<div style={{ fontSize: '0.7rem' }}>
														Email:
														<a href={`mailto:${info.data.EPT_Email}`} target={'_blank'} rel='noreferrer'>
															<span>&nbsp;</span>
															<u>{info.data.EPT_Email}</u>
														</a>
													</div>
												)}

												{/* company link */}
												{info.data.Web_Address && (
													<div style={{ color: '#4472c4', fontSize: '0.7rem' }}>
														<a href={info.data.Web_Address} target={'_blank'} rel='noreferrer'>
															<u>Emergency Physicians of Tidewater</u>
														</a>
													</div>
												)}

												{/* company address */}
												{info.data.EPT_Address && <div style={{ fontSize: '0.7rem' }}>{info.data.EPT_Address}</div>}
											</div>
										</div>
									</div>
								</Box>
							)}

							{/* ept logo */}
							<Box sx={{ display: 'flex', justifyContent: 'center' }}>
								<div style={{ display: 'block', justifyContent: 'center', maxWidth: '375px' }}>
									{/* ept logo */}
									<a href='http://ept911.com' target='_blank'>
										<div
											style={{
												display: 'flex',
												justifyContent: 'left',
												height: '59px',
												maxWidth: '223px',
												margin: 'auto',
												marginTop: '1rem',
											}}
										>
											<img src={eptLogo} alt='ept logo' />
										</div>
									</a>
								</div>
							</Box>
						</Stack>
					</Paper>

					{/* save vCard button */}
					{!loading && (
						<Box m={2} sx={{ display: 'flex', justifyContent: 'center' }}>
							<Tooltip text='Save vCard to your Contacts' placement='bottom'>
								<Button
									variant='contained'
									color='primary'
									startIcon={<ContactPhoneRoundedIcon />}
									onClick={create_vCard}
									disabled={loading || info.code !== 200}
								>
									Save vCard
								</Button>
							</Tooltip>
						</Box>
					)}
				</Container>
			</Grid>
		</Grid>
	);
}

export default People;
