import React from 'react';
import classes from './DirectoryCard.module.css';
import UserImage from '../ui/UserImage';
import { Stack, Box, Typography } from '@mui/material';

export default function DirectoryCard(props) {
	const member = props.member;

	//passes selected member up to MemberList
	const selectMember = () => {
		props.onHandleSelection(member.EPT_Email);
	};

	//stops event propogation for clicking on the file attachments in a fileCard
	const stopPropagation = (event) => {
		event.stopPropagation();
	};

	//if order by is First Name
	if (props.orderBy === 'First_Name') {
		return (
			<div className={classes.container} onClick={selectMember}>
				<div className={classes.justify}>
					<Box display={'flex'} alignContent={'center'} alignItems={'center'} margin={'auto'}>
						<UserImage photoURL={member.photoUrl} name={member.Preferred_Name + ' ' + member.Last_Name} size={100} />
					</Box>

					<div className={classes.memberCard}>
						<div className={classes.memberName}>{member.Preferred_Name + ' ' + member.Last_Name}</div>
						{member.Member_Type && <div className={classes.memberType}>{member.Member_Type}</div>}
						{member.Positions?.sort().map((position, index) => {
							return (
								<Stack key={index}>
									<Typography fontSize={'0.85rem'} fontWeight={'bold'} textAlign={'center'} color={'primary'}>
										{position}
									</Typography>
								</Stack>
							);
						})}
						<Stack sx={{ display: 'block', justifyContent: 'left', fontWeight: '500' }}>
							<Box m={1} sx={{ fontSize: '0.8rem' }}>
								Mobile: <a href={`tel:${member.Mobile_Phone}`}>{member.Mobile_Phone}</a>
							</Box>
							<Box m={1} sx={{ fontSize: '0.8rem' }}>
								Email:&#160;
								<a
									href={`mailto:${member.Recovery_Email || member.EPT_Email}`}
									target='_blank'
									rel='noreferrer'
									onClick={stopPropagation}
									className={classes.email}
								>
									{member.Recovery_Email || member.EPT_Email}
								</a>
							</Box>
						</Stack>
					</div>
				</div>
			</div>
		);
	}

	//if order by is Last Name
	if (props.orderBy === 'Last_Name') {
		return (
			<div className={classes.container} onClick={selectMember}>
				<div className={classes.justify}>
					<Box display={'flex'} alignContent={'center'} alignItems={'center'} margin={'auto'}>
						<UserImage photoURL={member.photoUrl} name={member.Preferred_Name + ' ' + member.Last_Name} size={100} />
					</Box>
					<div className={classes.memberCard}>
						<div className={classes.memberName}>
							{member.Last_Name}, {member.Preferred_Name}
						</div>
						{member.Member_Type && <div className={classes.memberType}>{member.Member_Type}</div>}
						{member.Positions?.sort().map((position, index) => {
							return (
								<Stack key={index}>
									<Typography fontSize={'0.85rem'} fontWeight={'bold'} textAlign={'center'} color={'primary'}>
										{position}
									</Typography>
								</Stack>
							);
						})}
						<Stack sx={{ display: 'block', justifyContent: 'left', fontWeight: '500' }}>
							<Box m={1} sx={{ fontSize: '0.8rem' }}>
								Mobile: <a href={`tel:${member.Mobile_Phone}`}>{member.Mobile_Phone}</a>
							</Box>
							<Box m={1} sx={{ fontSize: '0.8rem' }}>
								Email:&#160;
								<a
									href={`mailto:${member.Recovery_Email || member.EPT_Email}`}
									target='_blank'
									rel='noreferrer'
									onClick={stopPropagation}
									className={classes.email}
								>
									{member.Recovery_Email || member.EPT_Email}
								</a>
							</Box>
						</Stack>
					</div>
				</div>
			</div>
		);
	}

	//if order by is Email
	if (props.orderBy === 'Email') {
		return (
			<div className={classes.container} onClick={selectMember}>
				<div className={classes.justify}>
					<Box display={'flex'} alignContent={'center'} alignItems={'center'} margin={'auto'}>
						<UserImage photoURL={member.photoUrl} name={member.Preferred_Name + ' ' + member.Last_Name} size={100} />
					</Box>
					<div className={classes.memberCard}>
						<div className={classes.memberEmailOrderBy}>
							<a
								href={`mailto:${member.Recovery_Email || member.EPT_Email}`}
								target='_blank'
								rel='noreferrer'
								onClick={stopPropagation}
								className={classes.email}
							>
								{member.Recovery_Email || member.EPT_Email}
							</a>
						</div>
						{member.Member_Type && <div className={classes.memberType}>{member.Member_Type}</div>}
						{member.Positions?.sort().map((position, index) => {
							return (
								<Stack key={index}>
									<Typography fontSize={'0.85rem'} fontWeight={'bold'} textAlign={'center'} color={'primary'}>
										{position}
									</Typography>
								</Stack>
							);
						})}
						<Stack sx={{ display: 'block', justifyContent: 'left', fontWeight: '500' }}>
							<Box m={1} sx={{ fontSize: '0.8rem' }}>
								Name: {member.Preferred_Name + ' ' + member.Last_Name}
							</Box>
							<Box m={1} sx={{ fontSize: '0.8rem' }}>
								Mobile: <a href={`tel:${member.Mobile_Phone}`}>{member.Mobile_Phone}</a>
							</Box>
						</Stack>
					</div>
				</div>
			</div>
		);
	}
}
