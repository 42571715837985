import React, { useEffect, useState, Fragment, useMemo, useContext } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { Box, Typography } from '@mui/material';

import PageCards from '../components/ui/PageCards';
import AlertContext from '../components/ui/AlertContext';
import MRT_TABLE from '../components/ui/MRT_TABLE';

export default function PublicIDs() {
	const alertCtx = useContext(AlertContext);
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState(null);

	// Table columns
	const columns = useMemo(
		() => [
			{
				id: 'name',
				accessorFn: (row) => `${row.Preferred_Name} ${row.Last_Name}`,
				header: 'Name',
			},
			{
				id: 'EPT_Email',
				accessorKey: 'EPT_Email',
				header: 'EPT Email',
			},
			{
				accessorKey: 'Public_Directory_ID',
				header: 'Public Directory ID (with Link)',
				Cell: ({ cell }) => {
					return (
						<a href={`https://portal.ept911.com/people/${cell.getValue()}`} target='_blank'>
							{cell.getValue()}
						</a>
					);
				},
			},
		],
		[]
	);

	// Fetch data from Firestore
	useEffect(() => {
		const getData = async () => {
			setIsLoading(true);
			const functions = getFunctions();
			const Directory_Get_Users_Field = httpsCallable(functions, 'Directory_Get_Users_Field');
			const result = await Directory_Get_Users_Field({ FIELD: 'Public_Directory_ID' });
			if (result.data.code === 200) setData(result.data.data);
			else {
				alertCtx.setSeverity(result.data.status.toLowerCase());
				alertCtx.setMessage(result.data.message);
				alertCtx.setActive(true);
			}
			setIsLoading(false);
		};

		if (!data) getData();
	}, []);

	return (
		<Fragment>
			<PageCards>
				<Typography variant='h3' color='primary' mb={2} textAlign={'center'}>
					Public Directory Link
				</Typography>

				<Box sx={{ display: 'flex', justifyContent: 'center' }} mb={2}>
					<Typography variant='subtitle1' textAlign='center' sx={{ maxWidth: '70%' }}>
						The links below take you to the public facing directory (does not require EPT log in) for each person in
						EPT. It displays generic EPT information, along with the persons mobile phone, position(s) within EPT, and
						their EPT email address if the person has given consent for those items to be shared.
					</Typography>
				</Box>

				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<MRT_TABLE
						columns={columns}
						data={data || []}
						loading={isLoading}
						pageSize={20}
						sortBy={[{ Id: 'name', Direction: 'asc', TrueFalse: true }]}
						maxHeight={'65vh'}
						hiddenColumns={['EPT_Email']}
					/>
				</Box>
			</PageCards>
			<br />
		</Fragment>
	);
}
