import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';

import PageCards from '../ui/PageCards.js';
import { Alert, Typography, Stack, Box } from '@mui/material';
import LoadingSpinner from '../ui/LoadingSpinner.js';

const Vote = () => {
	const [status, setStatus] = useState({});
	const [loading, setLoading] = useState(true);
	const { documentID, email, vote } = useParams();

	useEffect(() => {
		if (documentID && email && vote) {
			const functions = getFunctions();
			const Voting_Verification = httpsCallable(functions, 'Voting_Verification');
			Voting_Verification({ documentID: documentID, email: email, vote: vote })
				.then((result) => {
					setLoading(false);
					if (result.data.success) {
						setStatus({
							status: 'success',
							message: `${result.data.message} - you can now close this window.`,
						});
					} else {
						setStatus({ status: 'error', message: `Your vote could not be recorded. Error - ${result.data.message}` });
					}
				})
				.catch((error) => {
					setLoading(false);
					console.error('Error calling Voting_Verification function:', error);
					setStatus({
						status: 'error',
						message: `An error occurred during vote verification. Try again later. - ${error}`,
					});
				});
		} else {
			setStatus('The correct parameters were not found in the URL.');
		}
	}, [documentID, email, vote]);

	return (
		<PageCards>
			<Typography variant='h3' color='primary' mb={2} textAlign={'center'}>
				Vote Verification Page
			</Typography>
			<Box display={'flex'} justifyContent={'center'}>
				<Stack display={'flex'} justifyContent={'center'} maxWidth={'500px'}>
					{loading && <Alert severity={'info'}>Please wait. Your vote is being logged.</Alert>}
					{loading && <LoadingSpinner />}
					{!loading && status?.status && <Alert severity={status.status}>{status.message}</Alert>}
				</Stack>
			</Box>
		</PageCards>
	);
};

export default Vote;
