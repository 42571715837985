import React, { useMemo, useEffect, useState, useContext } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { MaterialReactTable } from 'material-react-table';
import { Box, Typography, Stack, Select, MenuItem } from '@mui/material';

import moment from 'moment';
import * as d3 from 'd3';

import Tooltip from '../ui/Tooltip';
import LoadingSpinner from '../ui/LoadingSpinner';
import Dashboard_Query from './Dashboard_Query';
import AlertContext from '../ui/AlertContext';
import CustomClaimsContext from '../auth/CustomClaimsContext';

export default function Chart_Table({ chart, dashboard, onRendered }) {
	const alertCtx = useContext(AlertContext);
	const claimsCtx = useContext(CustomClaimsContext);
	const [loading, setLoading] = useState(null);
	const [data, setData] = useState(null);
	const [groupedData, setGroupedData] = useState([]);
	const [reportDates, setReportDates] = useState(null);
	const [selectedDate, setSelectedDate] = useState(null);

	// gets the data for the dashboard
	useEffect(() => {
		setLoading(true);

		const awaitQuery = async () => {
			setData(null);
			setGroupedData(null);
			setReportDates(null);
			setSelectedDate(null);

			console.log('dashboard: ', dashboard);
			console.log('chart: ', chart);
			// console.log('claims', claimsCtx.claims);
			let response;
			if (chart.data === 'Data-PHI') {
				const functions = getFunctions();
				const Dashboard_PHI_Query = httpsCallable(functions, 'Dashboard_PHI_Query');
				const result = await Dashboard_PHI_Query({ dashboard: dashboard, chart: chart, uid: claimsCtx.claims.user_id });
				response = result.data;

				if (result.data.error) {
					alertCtx.setMessage(result.message);
					alertCtx.setSeverity('error');
					alertCtx.setTitle('Data Query');
					alertCtx.setActive(true);
					setLoading(false);
					return;
				}
			} else {
				response = await Dashboard_Query(dashboard, chart);
			}
			console.log('response: ', response);

			/**
			 * if data is empty, then display alert
			 * also stop loading
			 */
			if (response.error) {
				if (response.error === 'Firebase Composite Index')
					alertCtx.setMessage(`${response.message} <a href='${response.url}' target='_blank'>Link</a>`);
				else alertCtx.setMessage(response.message);
				alertCtx.setSeverity('error');
				alertCtx.setTitle('Data Query');
				alertCtx.setActive(true);
				setLoading(false);
				return;
			} else if (!response.data) {
				console.log('No Data Returned');
				alertCtx.setMessage('No data returned.');
				alertCtx.setSeverity('warning');
				alertCtx.setTitle('Data Query');
				alertCtx.setActive(true);
				setLoading(false);
				return;
			} else if (response.data) {
				// console.log('data', response.data);
				setData(response.data);
			}
		};
		awaitQuery();
	}, [chart, dashboard]);

	// processes the dates based on the table settings
	useEffect(() => {
		if (data) {
			// console.log('data: ', data);

			//gets the dates for the menu
			const dateItems = [];
			data.forEach((item) => {
				dateItems.push({ date: item['Report Date'] });
			});

			const datesData = Array.from(
				d3.group(dateItems, (d) => d.date),
				([date]) => ({ date })
			);
			// console.log('datesData: ', datesData);

			/**
			 * display only the Most Recent date
			 */
			if (chart.displayDateRange === 'Most Recent') {
				// Convert the string dates to Date objects for comparison
				const dateObjects = datesData.map((dateItem) => new Date(dateItem.date));

				// Find the latest date
				const latestDate = new Date(Math.max.apply(null, dateObjects));

				// Set selectedDate to the latest date
				setSelectedDate(latestDate.toISOString());
			}

			/**
			 * display All Available Dates
			 */
			if (chart.displayDateRange === 'All Available') {
				setReportDates(datesData);

				// Convert the string dates to Date objects for comparison
				const dateObjects = datesData.map((dateItem) => new Date(dateItem.date));

				// Find the latest date
				const latestDate = new Date(Math.max.apply(null, dateObjects));

				// Set selectedDate to the latest date
				setSelectedDate(latestDate.toISOString());
			}
		}
	}, [data]);

	// groups data by
	useEffect(() => {
		if (selectedDate) {
			// Define an array to hold key functions
			const keyFunctions = [];

			// Conditionally add key functions based on chart settings
			if (chart.tableGroupDataByFirst) {
				keyFunctions.push((d) => d[chart.tableGroupDataByFirst]);
			}
			if (chart.tableGroupDataBySecond) {
				keyFunctions.push((d) => d[chart.tableGroupDataBySecond]);
			}

			console.log('data', data);

			// Use d3.rollup with dynamic key functions
			const tempGroupedData =
				Array.from(
					d3.rollup(
						data,
						(D) =>
							// D.length,
							({
								length: D.length,
								items: D,
							}),
						...keyFunctions // Spread the array into arguments
					),
					([key, value]) => ({ key, value })
				) || [];
			console.log('tempGroupedData:', tempGroupedData);

			/**
			 * counts how many times the item was over the threshold
			 */
			tempGroupedData.map((item) => {
				// console.log('item', item);
				if (item?.value) {
					// console.log('item.value', item.value); // Log value to see if it is iterable

					// Ensure item.value is defined
					Array.from(item.value, ([key, value]) => ({ key, value })).map((subItem) => {
						// console.log('subItem', subItem);
						if (subItem.value?.length > (chart?.tableThreshold || 0)) {
							item.count ? (item.count += 1) : (item.count = 1);
						}
					});
				}
			});

			console.log('tempGroupedData:', tempGroupedData);

			//removes people with no reports for selectedDate
			const filteredData = tempGroupedData.filter((x) => {
				// Assuming `x.value` is a Map that has keys which are dates
				if (x?.value instanceof Map) {
					// Normalize selected date to an ISO string or just the date part
					const selectedDateKey = new Date(selectedDate).toISOString().split('T')[0]; // YYYY-MM-DD

					// Check if any of the keys in `value` match `selectedDateKey`
					return Array.from(x.value.keys()).some((key) => {
						const keyISO = new Date(key).toISOString().split('T')[0]; // Convert the key to comparable format
						return keyISO === selectedDateKey;
					});
				}
				return false;
			});

			let finalTransformedData, newTransformedData;
			if (chart.data === 'Data-PHI') {
				newTransformedData = filteredData.flatMap((entry) =>
					Array.from(entry.value.values()).flatMap((value) =>
						value.items.map((item) => ({
							key: entry.key,
							...item,
						}))
					)
				);

				console.log('newTransformedData', newTransformedData);

				finalTransformedData = newTransformedData.filter((item) => {
					// Normalize both dates to the same format before comparison
					const reportDateISO = new Date(item['Report Date']).toISOString();
					const selectedDateISO = new Date(selectedDate).toISOString();

					return reportDateISO === selectedDateISO;
				});
			} else {
				finalTransformedData = filteredData;
			}

			console.log('finalTransformedData', finalTransformedData);
			setGroupedData(finalTransformedData);
			setLoading(false);
		}
	}, [selectedDate]);

	// sends feedback if the component is rendered, used for Dashboard_SendEmail component
	useEffect(() => {
		if (onRendered && loading === false) {
			onRendered(false); // Pass the loading state back
		}
	}, [loading]);

	const columnsWithSummary = useMemo(
		() => [
			{
				accessorKey: 'key',
				header: chart.tableGroupDataByFirst,
			},
			{
				accessorFn: (row) => row.value.get(new Date(selectedDate))?.length,
				header: chart.title,
				muiTableHeadCellProps: {
					align: 'center',
				},
				muiTableBodyCellProps: {
					align: 'center',
				},
				Cell: ({ cell }) => {
					return (
						<div
							style={{
								color: Number.isNaN(chart.tableThreshold)
									? 'black'
									: cell.getValue() > chart.tableThreshold
									? 'red'
									: 'black',
							}}
						>
							{cell.getValue()}
						</div>
					);
				},
			},
			{
				accessorFn: (row) => row.count,
				header: (
					<Stack direction='row' alignItems='center'>
						{chart.tableSummaryColumnTitle}
						{chart.tableSummaryColumnTooltip && chart.tableSummaryColumnTooltip.length > 0 && (
							<Tooltip text={chart.tableSummaryColumnTooltip} />
						)}
					</Stack>
				),
				muiTableHeadCellProps: {
					align: 'center',
				},
				muiTableBodyCellProps: {
					align: 'center',
				},
				Cell: ({ cell }) => {
					return (
						<div
							style={{
								color: Number.isNaN(chart.tableSummaryColumnThreshold)
									? 'black'
									: cell.getValue() > chart.tableSummaryColumnThreshold
									? 'red'
									: 'black',
							}}
						>
							{cell.getValue()}
						</div>
					);
				},
			},
		],
		[selectedDate]
	);

	const columnsWithoutSummary = useMemo(
		() => [
			{
				accessorFn: (row) => row['Chart ID'],
				header: 'Chart ID',
				muiTableHeadCellProps: {
					align: 'center',
				},
				muiTableBodyCellProps: {
					align: 'center',
				},
			},
			{
				accessorFn: (row) => row['Def Status'],
				header: 'Deficiency',
				muiTableHeadCellProps: {
					align: 'center',
				},
				muiTableBodyCellProps: {
					align: 'center',
				},
			},
			{
				accessorFn: (row) => row['Days Overdue'],
				header: 'Days Overdue',
				muiTableHeadCellProps: {
					align: 'center',
				},
				muiTableBodyCellProps: {
					align: 'center',
				},
			},
			{
				accessorFn: (row) => row['Dismissed'],
				header: 'Dismissed',
				muiTableHeadCellProps: {
					align: 'center',
				},
				muiTableBodyCellProps: {
					align: 'center',
				},
			},
			{
				accessorFn: (row) => row['Dismissed Reason'],
				header: 'Dismissed Reason',
				muiTableHeadCellProps: {
					align: 'center',
				},
				muiTableBodyCellProps: {
					align: 'center',
				},
			},
		],
		[selectedDate]
	);

	return (
		<>
			{/* display loading spinner */}
			{loading && <LoadingSpinner />}

			{!loading && groupedData && (
				<Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
					<Stack>
						<Typography variant='h6' align='center' mb={1}>
							{chart.dashboardName}
						</Typography>

						{/* don't display if there are multiple dates (reportDate) */}
						{!reportDates && (
							<Typography variant='h7' align='center' mb={1}>
								{moment(selectedDate).format('MMMM D, YYYY')}
							</Typography>
						)}

						{/* display if there are multiple dates (reportDate) */}
						{selectedDate && reportDates && (
							<Box sx={{ marginBottom: 2 }} display='flex' justifyContent='center'>
								<Select
									labelId='select-report-date-label'
									id='select-report-date'
									value={selectedDate}
									onChange={(e) => setSelectedDate(e.target.value)}
								>
									{reportDates.map((item, index) => (
										<MenuItem key={index} value={new Date(item.date).toISOString()}>
											{moment(item.date).format('MMMM D, YYYY')}
										</MenuItem>
									))}
								</Select>
							</Box>
						)}

						<MaterialReactTable
							muiTablePaperProps={{
								elevation: 0, //change the mui box shadow
								sx: {
									'& tr:nth-of-type(even)': {
										backgroundColor: '#f5f5f5', //stripe the rows, make even rows a darker color
									},
								},
							}}
							displayColumnDefOptions={{
								'mrt-row-actions': {
									muiTableHeadCellProps: {
										align: 'center',
									},
									size: 200,
								},
							}}
							columns={chart.tableSummaryColumn || false ? columnsWithSummary : columnsWithoutSummary}
							data={groupedData}
							enablePagination={false}
							initialState={{
								density: 'compact',
								expanded: true, //expand all groups by default
								sorting: [{ id: 'Chart ID', asc: true }], //sort by group by default
							}}
						/>
					</Stack>
				</Box>
			)}
		</>
	);
}
