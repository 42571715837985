import React, { useContext, useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import Grid from '@mui/material/Unstable_Grid2';

import AuthContext from '../components/auth/authContext';
import MyInfo from '../components/myInfo/MyInfo';
import EmailSignature from '../components/myInfo/EmailSignature';
import PublicInfoConsent from '../components/myInfo/PublicInfoConsent';
import CustomClaimsContext from '../components/auth/CustomClaimsContext';

function MyInfoPage() {
	const authCtx = useContext(AuthContext);
	const [newInfo, setNewInfo] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [loadedData, setLoadedData] = useState(null);
	const claimsCtx = useContext(CustomClaimsContext);
	const { claims } = claimsCtx;

	const handleNewInfo = (data) => {
		const adminResponse = data;
		setLoadedData((loadedData) => ({
			...loadedData,
			adminResponse,
		}));
		setNewInfo(true);
		setTimeout(() => {
			setNewInfo(false);
		}, 100);
	};

	// Get My Info on initial load
	useEffect(() => {
		const fetchData = async () => {
			if (claims?.email && authCtx.isLoggedIn) {
				setIsLoading(true);
				try {
					const functions = getFunctions();
					const getData = httpsCallable(functions, 'My_Info_Get_Data');
					const result = await getData({ uid: claims.user_id });

					if (result && result.data && result.data.code === 200) {
						const Data = result.data.data;
						setLoadedData(Data);
					}
				} catch (error) {
					console.error('Error fetching data:', error);
				}
				setIsLoading(false);
			}
		};

		fetchData();
	}, [claims, authCtx.isLoggedIn]);

	return (
		<Grid container spacing={1}>
			<Grid xs={12} md={6}>
				<MyInfo newInfo={handleNewInfo} loadedData={loadedData} isLoading={isLoading} />
			</Grid>
			<Grid xs={12} md={6}>
				<PublicInfoConsent loadedData={loadedData} isLoading={isLoading} />
				<EmailSignature newInfo={newInfo} isLoading={isLoading} loadedData={loadedData} />
			</Grid>
		</Grid>
	);
}

export default MyInfoPage;
