import React, { useContext, useEffect, useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import PageCards from '../components/ui/PageCards';
import AuthContext from '../components/auth/authContext';
import AlertContext from '../components/ui/AlertContext';
import CustomClaimsContext from '../components/auth/CustomClaimsContext';
import DirectoryList from '../components/directoryInfo/DirectoryList';
import DirectoryForm from '../components/directoryInfo/DirectoryForm';

function Directory() {
	const authCtx = useContext(AuthContext);
	const { isLoggedIn } = authCtx;
	const alertCtx = useContext(AlertContext);
	const claimsCtx = useContext(CustomClaimsContext);
	const [selectedMember, setSelectedMember] = useState();
	const [memberInfo, setMemberInfo] = useState();
	const [directoryLoading, setDirectoryLoading] = useState(false);
	const [memberLoading, setMemberLoading] = useState(false);
	const [list, setList] = useState(null);
	const [orderBy, setOrderBy] = useState('First_Name');
	const [sortOrder, setSortOrder] = useState('ASCENDING');
	const [orderedList, setOrderedList] = useState(null);
	const [memberType, setMemberType] = useState('ALL');
	const [selection, setSelection] = useState('Active');

	//handles member selection from DirectoryList
	const handleMemberSelection = (email) => {
		// console.log('selectedMember', selectedMember);
		// console.log('email', email);
		if (selectedMember !== email) {
			setSelectedMember(email);
			setMemberLoading(true);
			window.scrollTo(0, 0);
		}
	};

	//clears the selected member
	const handleClearSelectedMember = () => {
		setSelectedMember(null);
		window.scrollTo(0, 0);
	};

	//getMemberInfos
	useEffect(() => {
		// console.log('selectedMember', selectedMember);

		const getMemberInfos = async () => {
			const functions = getFunctions();
			const Directory_Get_User_Data = httpsCallable(functions, 'Directory_Get_User_Data');
			const result = await Directory_Get_User_Data({ member: selectedMember, admin: claimsCtx?.claims?.admin });

			if (result.data.code === 200) {
				// console.log('MemberInfo loaded:', result.data.data);
				setMemberInfo(result.data.data);
				setMemberLoading(false);
			}
		};
		getMemberInfos();
	}, [claimsCtx?.claims?.admin, selectedMember, selection, list]);

	//handles order by change
	const handleOrderByChange = (change) => {
		setDirectoryLoading(true);
		setOrderedList(null);

		switch (change) {
			case 'First_Name':
				setOrderBy('First_Name');
				return;
			case 'Last_Name':
				setOrderBy('Last_Name');
				return;
			case 'Email':
				setOrderBy('Email');
				return;
			default:
				setOrderBy('First_Name');
				return;
		}
	};

	// handles order by change
	const handleSortOrderChange = (change) => {
		setDirectoryLoading(true);
		setOrderedList(null);

		switch (change) {
			case 'ASCENDING':
				setSortOrder('ASCENDING');
				return;
			case 'DESCENDING':
				setSortOrder('DESCENDING');
				return;
			default:
				setSortOrder('ASCENDING');
				return;
		}
	};

	// handles member type change
	const handleMemberTypeChange = (change) => {
		setDirectoryLoading(true);
		setOrderedList(null);

		switch (change) {
			case 'ALL':
				setMemberType('ALL');
				return;
			case 'APP':
				setMemberType('APP');
				return;
			case 'OFFICE':
				setMemberType('OFFICE');
				return;
			case 'OTHER':
				setMemberType('OTHER');
				return;
			case 'PHYSICIAN':
				setMemberType('PHYSICIAN');
				return;
			case 'RESIDENT':
				setMemberType('RESIDENT');
				return;
			case 'UNCLASSIFIED':
				setMemberType('UNCLASSIFIED');
				return;
			default:
				setMemberType('ALL');
				return;
		}
	};

	//order by, sorts, and filters the list
	useEffect(() => {
		if (list && list.title && list.title === 'Error') {
			alertCtx.setMessage(`<strong>There was an error. Logout and log back in. If the error continues to occur, contact IT and provide this error
			message: ${list.message}.</strong>`);
			alertCtx.setSeverity('error');
			alertCtx.setActive(true);
		} else if (list === 'No Deleted Users') {
			setOrderedList(null);
		} else if (list) {
			let orderByVariable;
			if (orderBy === 'First_Name') {
				orderByVariable = 'Preferred_Name';
			} else if (orderBy === 'Last_Name') {
				orderByVariable = 'Last_Name';
			} else if (orderBy === 'Email') {
				orderByVariable = 'Email';
			}

			let orderingList;
			if (sortOrder === 'ASCENDING') {
				orderingList = list.sort((a, b) => {
					const aValue = a?.[orderByVariable]?.toLowerCase() || '';
					const bValue = b?.[orderByVariable]?.toLowerCase() || '';
					return aValue > bValue ? 1 : bValue > aValue ? -1 : 0;
				});
			} else if (sortOrder === 'DESCENDING') {
				orderingList = list.sort((a, b) => {
					const aValue = a?.[orderByVariable]?.toLowerCase() || '';
					const bValue = b?.[orderByVariable]?.toLowerCase() || '';
					return aValue > bValue ? -1 : bValue > aValue ? 1 : 0;
				});
			}

			let results;
			if (memberType === 'ALL') {
				results = orderingList;
			} else if (
				memberType === 'APP' ||
				memberType === 'APP - Tier 1' ||
				memberType === 'APP - Tier 2' ||
				memberType === 'APP - Tier 3' ||
				memberType === 'APP - Tier 4'
			) {
				results = orderingList.filter((member) => {
					if (member.Member_Type !== null && member.Member_Type !== undefined) {
						return member.Member_Type.toLowerCase().includes('APP'.toLowerCase());
					}
				});
			} else if (memberType === 'OFFICE') {
				results = orderingList.filter((member) => {
					if (member.Member_Type !== null && member.Member_Type !== undefined) {
						return member.Member_Type.toLowerCase().includes('OFFICE'.toLowerCase());
					}
				});
			} else if (memberType === 'OTHER') {
				results = orderingList.filter((member) => {
					if (member.Member_Type !== null && member.Member_Type !== undefined) {
						return member.Member_Type.toLowerCase().includes('OTHER'.toLowerCase());
					}
				});
			} else if (memberType === 'PHYSICIAN') {
				results = orderingList.filter((member) => {
					if (member.Member_Type !== null && member.Member_Type !== undefined) {
						return member.Member_Type.toLowerCase().includes('PHYSICIAN'.toLowerCase());
					}
				});
			} else if (memberType === 'RESIDENT') {
				results = orderingList.filter((member) => {
					if (member.Member_Type !== null && member.Member_Type !== undefined) {
						return member.Member_Type.toLowerCase().includes('RESIDENT'.toLowerCase());
					}
				});
			} else if (memberType === 'UNCLASSIFIED') {
				results = orderingList.filter((member) => {
					if (member.Member_Type === null || member.Member_Type === undefined) {
						return member;
					}
				});
			}

			setOrderedList(results);
		}
	}, [list, alertCtx, sortOrder, orderBy, memberType]);

	//stops loading once orderedList is set
	useEffect(() => {
		if (orderedList) setDirectoryLoading(false);
	}, [orderedList]);

	//getDirectory
	useEffect(() => {
		const getDirectory = async () => {
			try {
				const functions = getFunctions();
				const Directory_Get_Users = httpsCallable(functions, 'Directory_Get_Users');
				// console.log('Selection:', selection);
				const result = await Directory_Get_Users({ selection: selection });
				// console.log('Result', result);
				if (result.data.code === 200) {
					setList(result.data.data);
					setMemberLoading(false);
				}
			} catch (error) {
				console.error('Error loading directory:', error);
			} finally {
				setDirectoryLoading(false);
			}
		};

		if (isLoggedIn) {
			setDirectoryLoading(true);
			getDirectory();
		}
	}, [selection]);

	//clears the current selected member
	const handleSelectionChange = (e) => {
		setOrderedList(null);
		handleClearSelectedMember();
		setSelection(e);
	};

	return (
		<Grid container spacing={1}>
			<Grid xs={12}>
				<PageCards>
					<Typography variant='h3' color={'primary'} textAlign='center'>
						EPT Directory
					</Typography>
				</PageCards>
			</Grid>

			{/* Directory Form - do not display when on md or larger screens (>= 900 px) */}
			<Grid sm={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
				<PageCards>
					<DirectoryForm isLoading={memberLoading} memberInfo={memberInfo} onClose={handleClearSelectedMember} />
				</PageCards>
			</Grid>

			{/* directory list */}
			<Grid xs={12} md={4}>
				<PageCards>
					<DirectoryList
						userEmail={claimsCtx?.claims?.email}
						loggedIn={isLoggedIn}
						onMemberSelection={handleMemberSelection}
						list={orderedList}
						onOrderByChange={handleOrderByChange}
						onSortOrderChange={handleSortOrderChange}
						onMemberTypeChange={handleMemberTypeChange}
						isLoading={directoryLoading}
						orderBy={orderBy}
						sortOrder={sortOrder}
						memberType={memberType}
						selection={selection}
						onSelectionChange={handleSelectionChange}
					/>
				</PageCards>
			</Grid>

			{/* Directory Form - do not display when on xs or sm screens (< 900 px) */}
			<Grid md={8} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
				<PageCards>
					<DirectoryForm isLoading={memberLoading} memberInfo={memberInfo} onClose={handleClearSelectedMember} />
				</PageCards>
			</Grid>

			<br></br>
		</Grid>
	);
}

export default Directory;
