import React, { useContext, useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

import moment from 'moment';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Typography, Button, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import PageCards from '../components/ui/PageCards';
import { db, functions } from '../App';
import LoadingSpinner from '../components/ui/LoadingSpinner';
import AlertContext from '../components/ui/AlertContext';

export default function Vacation() {
	const alertCtx = useContext(AlertContext);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [maxDays, setMaxDays] = useState(null);
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
	const [loading, setLoading] = useState(true);
	const [submitting, setSubmitting] = useState(false);

	// Get the user data and MaxDays
	useEffect(() => {
		const fetchMaxDays = async () => {
			const docRef = doc(db, 'settings', 'Outside_US_OU');
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				setMaxDays(docSnap.data().MaxDays);
			} else {
				console.log('No such document!');
			}
		};

		const getUserData = async () => {
			const Outside_US_Get_User_Details = httpsCallable(functions, 'Outside_US_Get_User_Details');
			const tempData = await Outside_US_Get_User_Details();
			if (tempData.data.success) {
				const data = tempData.data.data.Outside_US_Access;
				console.log('tempData:', tempData.data.data);
				setStartDate(data?.StartDate ? moment(data.StartDate._seconds * 1000) : null);
				setEndDate(data?.EndDate ? moment(data.EndDate._seconds * 1000) : null);
			} else {
				alertCtx.setMessage(tempData.data.message);
				alertCtx.setSeverity('error');
				alertCtx.setTitle('User Data Not Found');
				alertCtx.setActive(true);
			}
		};

		const runInParallel = async () => {
			await Promise.all([fetchMaxDays(), getUserData()]);
			setLoading(false); // Set loading to false after fetching data
		};

		runInParallel();
	}, []);

	// Check if the submit button should be disabled
	useEffect(() => {
		if (startDate && endDate && maxDays) {
			const isEndDateAfterStartDate = endDate.isAfter(startDate);
			const isWithinMaxDays = endDate.diff(startDate, 'days') <= maxDays;
			setIsSubmitDisabled(!(isEndDateAfterStartDate && isWithinMaxDays));
		} else {
			setIsSubmitDisabled(true);
		}
	}, [startDate, endDate, maxDays]);

	// Function to switch the user OU
	const switchUserOU = async () => {
		setSubmitting(true);
		const Outside_US_Switch_User = httpsCallable(functions, 'Outside_US_Switch_User');

		try {
			const endDateWithTime = endDate.clone().add(1, 'days').set({ hour: 23, minute: 59, second: 0, millisecond: 0 });
			const resp = await Outside_US_Switch_User({
				Start_Date: startDate.toISOString(),
				End_Date: endDateWithTime.toISOString(),
			});

			alertCtx.setMessage(resp.data.message);
			alertCtx.setSeverity(resp.data.success ? 'success' : 'error');
			alertCtx.setTimer(10000);
			alertCtx.setTitle(resp.data.success ? 'User Updated Successfully' : 'User Not Updated');
			alertCtx.setActive(true);
		} catch (err) {
			console.error('Error updating user OU:', err);
			alertCtx.setMessage(err);
			alertCtx.setSeverity('error');
			alertCtx.setTitle('User Not Updated');
			alertCtx.setActive(true);
		}
		setSubmitting(false);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Grid container spacing={1}>
				{/* user input */}
				<Grid xs={12}>
					<PageCards>
						{/* Title */}
						<Typography variant='h3' color='primary' mb={2} textAlign={'center'}>
							Vacation
						</Typography>

						{/* Details */}
						<Typography
							variant='h7'
							color='primary'
							mb={2}
							textAlign={'center'}
							justifyContent={'center'}
							display={'block'}
						>
							Utilize this option if you are traveling outside of the US and want access to your EPT Google Workspace
							for the following items:
							<br /> - Email
							<br /> - Calendar
							<br /> - Google Chat
							<br /> - Google Meet
							<br /> - Keep
							<br /> - Tasks
							<br />
							<br />
							No additional Google Workspace items are available when outside the US.
						</Typography>

						{/* Notes */}
						<Typography textAlign={'center'} fontWeight={'bold'}>
							Notes:
						</Typography>
						<Typography textAlign={'center'}>
							Access can take several hours to take affect.
							<br />
							The max number of days a user can request is 14. If you need more than 14 days you will need to log in
							after your prior request has finished and re-request another time period.
						</Typography>

						{/* Loading Spinner */}
						{loading && <LoadingSpinner />}

						{/* Date Picker */}
						{!loading && (
							<Box mt={3}>
								<LocalizationProvider dateAdapter={AdapterMoment}>
									<Grid container spacing={2} justifyContent='center'>
										<Grid>
											<DatePicker
												label='Start Day'
												value={startDate}
												onChange={(newValue) => setStartDate(newValue)}
												slots={{ textField: TextField }}
											/>
										</Grid>
										<Grid>
											<DatePicker
												label='Last Day'
												value={endDate}
												onChange={(newValue) => setEndDate(newValue)}
												slots={{ textField: TextField }}
											/>
										</Grid>
									</Grid>
								</LocalizationProvider>
							</Box>
						)}

						{/* Submit Button */}
						{!loading && (
							<Grid container justifyContent='center' mt={2}>
								{submitting && <LoadingSpinner />}
								{!submitting && (
									<Button variant='contained' color='primary' onClick={switchUserOU} disabled={isSubmitDisabled}>
										Submit
									</Button>
								)}
							</Grid>
						)}
					</PageCards>
				</Grid>
			</Grid>
		</Box>
	);
}
