import React, { Fragment, useContext, useState } from 'react';
import { Stack, Chip, Box, Modal, Typography, Button, IconButton } from '@mui/material';
import SurfingRoundedIcon from '@mui/icons-material/SurfingRounded';
import TimeToLeaveRoundedIcon from '@mui/icons-material/TimeToLeaveRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import RefreshIcon from '@mui/icons-material/Refresh';

import moment from 'moment';

import Tooltip from '../ui/Tooltip';
import CustomClaimsContext from '../auth/CustomClaimsContext';

export default function MonthlyColumns({ row, yearMonth, onRefreshClick, tickLogs }) {
	const rowOriginal = row.original;
	const claimsCtx = useContext(CustomClaimsContext);
	const persons = rowOriginal.tickLogs[yearMonth];

	// State for modal
	const [openModal, setOpenModal] = useState(false);
	const [selectedPerson, setSelectedPerson] = useState(null);

	const handleOpenModal = (person) => {
		setSelectedPerson(person);
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
		setSelectedPerson(null);
	};

	const handleConfirmRefresh = () => {
		onRefreshClick(yearMonth, rowOriginal); // Trigger refresh action
		handleCloseModal(); // Close modal
	};

	const Icons = (props) => {
		const { person } = props;

		if (!person || !person.flag) {
			return null;
		}

		return (
			<>
				{person.flag.Sabbatical && (
					<Tooltip text={`Sabbatical for ${person.flag.sabbaticalDays} days.`}>
						<SurfingRoundedIcon fontSize='small' />
					</Tooltip>
				)}

				{person.flag.LOA && (
					<Tooltip text={`Leave of Absence for ${person.flag.loaDays} days.`}>
						<TimeToLeaveRoundedIcon fontSize='small' />
					</Tooltip>
				)}
				{person.flag.startDateFlag?.setting && (
					<Tooltip
						text={`${person.personName} started their role for this position on ${moment(
							person.flag.startDateFlag.date.toDate()
						).format('MMM D, YYYY')} for a total of ${person.flag.monthlyDays} days this month.`}
					>
						<CalendarMonthRoundedIcon fontSize='small' />
					</Tooltip>
				)}
				{person.flag.endDateFlag?.setting && (
					<Tooltip
						text={`${person.personName} ended their role for this position on ${moment(
							person.flag.endDateFlag.date.toDate()
						).format('MMM D, YYYY')} for a total of ${person.flag.monthlyDays} days this month.`}
					>
						<CalendarMonthRoundedIcon fontSize='small' />
					</Tooltip>
				)}
			</>
		);
	};

	return (
		<Box display='flex' justifyContent='center'>
			<Stack>
				{persons?.length > 0 &&
					persons.map((person, index) => {
						return (
							<Fragment key={person.log}>
								{index > 0 ? <br /> : ''}
								<Stack direction='row' spacing={1}>
									<Box>
										<Chip
											icon={<Icons person={person} />}
											label={`${person.personName}: ${Math.round(person.totalHours * 10) / 10} of ${
												Math.round(person.allottedHours * 10) / 10
											} hours`}
											variant='outlined'
											clickable
											component='a'
											target='_blank'
											href={person.log}
											color='primary'
											sx={{
												display: 'flex',
												minHeight: '2rem',
												height: 'auto',
												justifyContent: 'center',
												textAlign: 'center',
												alignItems: 'center',
												'& .MuiChip-label': {
													display: 'flex',
													wordWrap: 'normal',
													whiteSpace: 'normal',
													textOverflow: 'clip',
													textAlign: 'center',
													justifyContent: 'center',
													width: '100px',
												},
												padding: '2px',
											}}
										/>
										{(claimsCtx.claims.admin ||
											claimsCtx.claims.BoardOfDirectors ||
											claimsCtx.claims.PBCCommittee ||
											claimsCtx.claims.email === person.personEmail) && (
											<IconButton
												onClick={() => handleOpenModal(person)}
												style={{ cursor: 'pointer', padding: '4px' }} // Smaller button with padding
												size='small' // Smaller size
											>
												<RefreshIcon fontSize='small' />
											</IconButton>
										)}
									</Box>
								</Stack>
							</Fragment>
						);
					})}
				{(!persons || persons?.length === 0) && 'No Data'}
			</Stack>

			{/* Confirmation Modal */}
			<Modal
				open={openModal}
				onClose={handleCloseModal}
				aria-labelledby='confirm-refresh-title'
				aria-describedby='confirm-refresh-description'
			>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						maxWidth: 400,
						bgcolor: 'background.paper',
						border: '2px solid #000',
						boxShadow: 24,
						p: 4,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Typography id='confirm-refresh-title' variant='h6' component='h2' gutterBottom>
						Confirm Refresh
					</Typography>
					<Typography id='confirm-refresh-description' sx={{ mb: 2 }}>
						Are you sure you want to refresh the logs for {selectedPerson?.personName} for {yearMonth}?
					</Typography>
					<Stack direction='row' spacing={2}>
						<Button variant='contained' color='primary' onClick={handleConfirmRefresh}>
							Yes
						</Button>
						<Button variant='outlined' color='secondary' onClick={handleCloseModal}>
							No
						</Button>
					</Stack>
				</Box>
			</Modal>
		</Box>
	);
}
