import React, { useContext, useEffect, useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { collection, getDocs } from 'firebase/firestore';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Typography } from '@mui/material';

import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as FileSaver from 'file-saver';

import PageCards from '../components/ui/PageCards';
import CustomClaimsContext from '../components/auth/CustomClaimsContext';
import AlertContext from '../components/ui/AlertContext';
import LoadingSpinner from '../components/ui/LoadingSpinner';
import { db } from '../App';
import sendGrid from '../components/sendGrid/sendGrid';
import nestHtmlList from '../components/localFunctions/nestHtmlList';

function BulkUpdate() {
	const claimsCtx = useContext(CustomClaimsContext);
	const alertCtx = useContext(AlertContext);
	const [selectionChoices, setSelectionChoices] = useState(null);
	const [selection, setSelection] = useState(null);
	const [loading, setLoading] = useState(true);
	const [isLoadingDownloadSection, setIsLoadingDownloadSection] = useState(null);
	const [isLoadingUploadSection, setIsLoadingUploadSection] = useState(null);
	const [uploadNumber, setUploadNumber] = useState({ current: 0, total: 0, display: false });

	//loads selectionChoices and selection from Firestore DirectorySettings
	useEffect(() => {
		if (!selectionChoices) {
			const getChoices = async () => {
				setLoading(true);
				const tempChoices = [];
				const tempSelection = {};
				const querySnapshot = await getDocs(collection(db, 'Directory_Settings'));
				querySnapshot.forEach((doc) => {
					// console.log(doc.data());
					tempChoices.push({ ...doc.data(), included: false });
					tempSelection[doc.data().Label] = false;
				});

				tempChoices.sort((a, b) => {
					if (a.Label < b.Label) {
						return -1;
					}
					if (a.Label > b.Label) {
						return 1;
					}
					return 0;
				});

				setSelectionChoices(tempChoices);
				setSelection(tempSelection);
				setLoading(false);
			};
			getChoices();
		}
	}, [db]);

	// handles selection
	const handleSelection = (choice) => {
		const truthy = selection[choice.Label];
		setSelection((prevState) => ({
			...prevState,
			[choice.Label]: !truthy,
		}));
	};

	// handles selecting all choices
	const handleSelectAll = () => {
		for (const choice of selectionChoices) {
			setSelection((prevState) => ({
				...prevState,
				[choice.Label]: true,
			}));
		}
	};

	// unselect all choices
	const handleUnselectAll = () => {
		for (const choice of selectionChoices) {
			setSelection((prevState) => ({
				...prevState,
				[choice.Label]: false,
			}));
		}
	};

	//handles bulk download
	const bulkDownloadHandler = async () => {
		//if no selection, then display error
		const items = [];
		for (const [key, value] of Object.entries(selection)) {
			if (value === true) items.push(key);
		}
		if (items.length === 0) {
			alertCtx.setMessage('Select at least one option from the list.');
			alertCtx.setSeverity('warning');
			alertCtx.setTitle('Warning');
			alertCtx.setActive(true);
			return;
		}

		setIsLoadingDownloadSection(true);

		//get current bulk list
		let bulkList;
		const functions = getFunctions();
		const Bulk_Update_Get_Data = httpsCallable(functions, 'Bulk_Update_Get_Data');
		await Bulk_Update_Get_Data({ selections: items })
			.then((result) => {
				if (result) {
					console.log(result);
					if (result.data.status !== 'success') {
						alertCtx.setMessage(result.data.message);
						alertCtx.setSeverity(result.data.status);
						alertCtx.setTitle('Error');
						alertCtx.setActive(true);
						return;
					} else if (!result.data.data) {
						alertCtx.setMessage(result.data.data);
						alertCtx.setSeverity('warning');
						alertCtx.setTitle('No Data');
						alertCtx.setActive(true);
						return;
					}
					bulkList = result.data.data;
				}
			})
			.then(() => {
				//creates new workbook and sheet inside workbook
				const workbook = new Excel.Workbook();
				const worksheet = workbook.addWorksheet(`Sheet1`);
				worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }];

				//defines columns
				const headers = bulkList.shift();
				const columns = [];
				for (const header of headers) columns.push({ name: header, filterButton: false });

				//adds table to worksheet
				worksheet.addTable({
					name: 'Table',
					ref: 'A1',
					headerRow: true,
					totalsRow: false,
					style: {
						theme: 'TableStyleMedium2',
						showRowStripes: true,
					},
					columns: columns,
					rows: bulkList,
				});

				//set alignment and width
				for (let i = 1; i <= columns.length; i++) {
					worksheet.getColumn(i).alignment = { wrapText: true, vertical: 'middle' };
					worksheet.getColumn(i).width = 30;
					if (i >= 3) {
						worksheet.getColumn(i).alignment = { horizontal: 'center' };
					}
				}
				worksheet.getRow(1).alignment = { horizontal: 'center' };

				//write fill to buffer and then save as XLSX
				workbook.xlsx.writeBuffer().then((data) => {
					const blob = new Blob([data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
					});
					FileSaver.saveAs(blob, 'Bulk Directory Update Spreadsheet');
				});

				setIsLoadingDownloadSection(false);
			});
	};

	//handles bulk upload
	const bulkUploadHandler = async (e) => {
		if (!e.target.files) {
			return;
		}

		setIsLoadingUploadSection(true);

		const f = e.target.files[0];
		const workbook = new Excel.Workbook();
		var fileReader = new FileReader();
		fileReader.onload = (e) => {
			const buffer = e.target.result;
			workbook.xlsx
				.load(buffer)
				.then(async (workbook) => {
					const data = [];
					// console.log('readFile success');

					const ws = workbook.getWorksheet('Sheet1');
					await ws.eachRow(function (row, rowNumber) {
						data.push(row.values.slice(1)); //removes the first item using slice, as the first item from excelJS is always empty to account of 0 order computations
						// console.log('Row ' + rowNumber + ' = ' + JSON.stringify(row.values));
					});

					// loops through upload file loading 50 at a time
					const headers = [data.shift()];
					setUploadNumber((prevState) => ({
						...prevState,
						total: data.length,
						display: true,
					}));
					const functions = getFunctions();
					const Bulk_Update_Upload_Data = httpsCallable(functions, 'Bulk_Update_Upload_Data');
					do {
						if (data.length > 50) {
							const tempArray = [];
							for (let i = 0; i < 50; i++) {
								tempArray.push(data.shift());
							}
							const arrayToUpload = [...headers, ...tempArray];
							// console.log(arrayToUpload);
							await Bulk_Update_Upload_Data({ data: arrayToUpload }).then((result) => {
								// console.log(result);
								if (result.data.status === 'Success') {
									setUploadNumber((prevState) => ({
										...prevState,
										current: prevState.current + 50,
									}));
								} else {
									alertCtx.setMessage(result.data.message);
									alertCtx.setSeverity('error');
									alertCtx.setTitle('Error');
									alertCtx.setActive(true);
								}
							});
						}
					} while (data.length > 50);

					// loops through and loads the last 50 or less items
					do {
						const tempArray = [];
						for (let i = 0; i < data.length; i++) {
							tempArray.push(data.shift());
						}
						const arrayToUpload = [...headers, ...tempArray];
						// console.log(arrayToUpload);
						await Bulk_Update_Upload_Data({ data: arrayToUpload }).then(async (result) => {
							if (result.data.status === 'Success') {
								setUploadNumber((prevState) => ({
									...prevState,
									current: prevState.current + tempArray.length,
								}));
								if (data.length === 0) {
									alertCtx.setMessage(result.data.message);
									alertCtx.setSeverity('success');
									alertCtx.setTitle('Success');
									alertCtx.setTimer(10000);
									alertCtx.setActive(true);

									const template_id = 'd-39ef9df22b47492c83bf6d72b6b393ee'; //default, without inline image
									const emailHTML = `${claimsCtx.claims.name} has performed a bulk upload on <a href='portal.ept911.com'>Portal</a>.`;

									const response = await sendGrid({
										to: 'eptoffice@ept911.com',
										cc: null,
										bcc: null,
										replyTo: claimsCtx.claims.email,
										plainText: emailHTML,
										template_id: template_id,
										emailSubject: 'Portal - Bulk Update Performed',
										emailTitle: 'Bulk Update Performed',
										emailHTML: nestHtmlList(emailHTML),
										fromPerson: null,
										fromGroup: null,
										attachments: null,
									});
									// console.log(response);

									alertCtx.setSeverity(response.data.code === 200 ? 'success' : 'error');
									alertCtx.setMessage(response.data.message);
									alertCtx.setTimer(response.data.code === 200 ? 10000 : null);
									alertCtx.setActive(true);
								}
							} else {
								alertCtx.setMessage(result.data.message);
								alertCtx.setSeverity('error');
								alertCtx.setTitle('Error');
								alertCtx.setActive(true);
							}
						});
					} while (data.length <= 50 && data.length > 0);

					setIsLoadingUploadSection(false);
					setUploadNumber((prevState) => ({
						...prevState,
						current: 0,
						total: 0,
						display: false,
					}));
				})
				.catch((error) => {
					// console.log('readFile fail', error);
					alertCtx.setMessage(`Error - refresh the page and try again.  Error message: ${error}`);
					alertCtx.setSeverity('error');
					alertCtx.setTitle('Error');
					alertCtx.setActive(true);
					setIsLoadingUploadSection(false);
				});
		};

		fileReader.readAsArrayBuffer(f);
	};

	return (
		<Grid container spacing={1} mb={10}>
			{/* page header */}
			<Grid xs={12}>
				<PageCards>
					<Typography variant='h3' textAlign={'center'} color='primary' mb={2}>
						Bulk Directory Update
					</Typography>

					{/* Instructions */}
					<Typography variant='h4' color={'primary'} textAlign={'center'}>
						Instructions
					</Typography>
					<Box display={'flex'} justifyContent={'center'}>
						<div>
							<ol>
								<li>Select at least one item from the dropdown menu to update.</li>
								<li>
									Click the <b>Download</b> button to download the most recent information as an Excel file.
								</li>
								<li>Open the Excel file.</li>

								<br></br>

								<li>
									<b>Important:</b> Do not edit columns A or B. All other pre-populated columns can be adjusted.
								</li>
								<li>To remove an item, leave the cell empty.</li>
								<li>
									To add an item, put the appropriate information in the cell.
									<ol>
										<li>
											<b>Important: </b>For email addresses, remove any hyperlink that is created.
										</li>
									</ol>
								</li>

								<br></br>

								<li>
									When done editing, save the file and select <i>Choose File</i> which will open a popup window.
								</li>
								<li>
									Find the correct file, and upload the file by selecting <b>Open</b> on the popup window.
								</li>
								<li>A confirmation prompt will be displayed if the upload and edits are applied successfully.</li>
							</ol>
						</div>
					</Box>

					{/* if loading return loader */}
					{loading && <LoadingSpinner />}

					{/* Download Section */}
					{!loading && (
						<Stack border={'1px solid lightgrey'} borderRadius={'8px'} sx={{ backgroundColor: '#f0f1f3' }} m={2}>
							<Typography variant='h4' textAlign={'center'} color='primary' m={2}>
								Download Section
							</Typography>

							{/* select and unselect all buttons */}
							<Stack direction='row' spacing={2} justifyContent='center' mb={1}>
								{/* check all */}
								<Button
									variant='contained'
									color='secondary'
									onClick={handleSelectAll}
									disabled={isLoadingDownloadSection}
								>
									Select All
								</Button>

								{/* uncheck all */}
								<Button
									variant='contained'
									color='secondary'
									onClick={handleUnselectAll}
									disabled={isLoadingDownloadSection}
								>
									Unselect All
								</Button>
							</Stack>

							{/* selection choices */}
							<Grid container spacing={2} columns={8} justifyContent='center'>
								{selectionChoices.map((choice, index) => (
									<Grid xs={2} key={index}>
										<FormGroup>
											<FormControlLabel
												control={
													<Checkbox
														disabled={isLoadingDownloadSection}
														checked={selection[choice.Label]}
														id={choice.Label}
														name={choice.Label}
														onChange={() => {
															handleSelection(choice);
														}}
													/>
												}
												labelPlacement='top'
												label={choice.Label}
											/>
										</FormGroup>
									</Grid>
								))}
							</Grid>

							{/* if loading return loader */}
							{isLoadingDownloadSection && <LoadingSpinner />}

							{/* download button */}
							{!isLoadingDownloadSection && (
								<Stack direction='row' spacing={2} justifyContent='center' m={2}>
									<Button
										variant='contained'
										startIcon={<DownloadIcon />}
										color='saveButton'
										onClick={bulkDownloadHandler}
									>
										DOWNLOAD
									</Button>
								</Stack>
							)}
						</Stack>
					)}

					{/* Upload Section */}
					{!loading && (
						<Stack border={'1px solid lightgrey'} borderRadius={'8px'} sx={{ backgroundColor: '#f0f1f3' }} m={2}>
							<Typography variant='h4' textAlign={'center'} color='primary' mt={3} mb={2}>
								Upload Section
							</Typography>

							{/* if loading return loader */}
							{isLoadingUploadSection && (
								<>
									<LoadingSpinner /> <br />
								</>
							)}

							{/* uploading counter */}
							{uploadNumber.display && (
								<Box m='auto' sx={{ width: '50%' }}>
									<Alert severity='info'>
										<AlertTitle>Info</AlertTitle>
										Uploaded <strong>{uploadNumber.current}</strong> of <strong>{uploadNumber.total}</strong>
									</Alert>
								</Box>
							)}

							{/* if not loading display upload button */}
							{!isLoadingUploadSection && (
								<Stack direction='row' spacing={2} justifyContent='center' mb={1}>
									<Button variant='contained' component='label' startIcon={<FileUploadIcon />} color='otherButton'>
										UPLOAD FILE
										<input type='file' hidden onChange={bulkUploadHandler} />
									</Button>
								</Stack>
							)}
						</Stack>
					)}
				</PageCards>
			</Grid>
			<br />
		</Grid>
	);
}

export default BulkUpdate;
