import React, { useState, useEffect, useContext, useMemo } from 'react';
import { getDocs, doc, collection, writeBatch, addDoc, deleteDoc } from 'firebase/firestore';
import {
	Button,
	Grid,
	TextField,
	Checkbox,
	MenuItem,
	Select,
	IconButton,
	Typography,
	Box,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableContainer,
	Paper,
	Collapse,
} from '@mui/material';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LoadingSpinner from '../ui/LoadingSpinner';
import AlertContext from '../ui/AlertContext';
import { db } from '../../App';

function DirectorySettings() {
	const alertCtx = useContext(AlertContext);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [updatingData, setUpdatingData] = useState(false);
	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const [expandedRows, setExpandedRows] = useState({});
	const [newField, setNewField] = useState({
		Label: '',
		Field_Key: '',
		FieldType: 'STRING',
		Order: '',
		Active: false,
		Public: false,
		Editable_By_User: false,
		Editable_By_Admin: false,
		DropDownChoices: [],
	});

	// Load data from Firestore
	useEffect(() => {
		async function fetchData() {
			setLoading(true);
			try {
				const querySnapshot = await getDocs(collection(db, 'Directory_Settings'));
				const settingsData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
				const sortedData = [...settingsData].sort((a, b) => a.Order - b.Order);
				setData(sortedData);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
			setLoading(false);
		}
		fetchData();
	}, []);

	// Handle form changes for existing fields
	const handleFormChange = (value, id, field) => {
		setData((prevData) => {
			const itemIndex = prevData.findIndex((item) => item.id === id);
			if (itemIndex === -1) return prevData; // Item not found
			const updatedItem = { ...prevData[itemIndex], [field]: value };
			if (field === 'Label') {
				updatedItem['Field_Key'] = value.replace(/\s+/g, '_');
			}
			const newData = [...prevData];
			newData[itemIndex] = updatedItem;
			return newData;
		});
		setUnsavedChanges(true);
	};

	// Handle order change
	const handleOrderChange = (newOrder, id, adjacentId, currentOrder) => {
		setData((prevData) =>
			prevData
				.map((item) => {
					if (item.id === id) {
						return { ...item, Order: newOrder };
					} else if (item.id === adjacentId) {
						return { ...item, Order: currentOrder };
					}
					return item;
				})
				.sort((a, b) => a.Order - b.Order)
		);
		setUnsavedChanges(true);
	};

	// Add a new row
	const handleAddRow = async () => {
		setUpdatingData(true);
		try {
			const fieldKey = newField.Label.trim().replace(/\s+/g, '_');
			const order = data.length + 1;
			const newDoc = {
				...newField,
				Field_Key: fieldKey,
				Order: order,
			};
			const newDocRef = await addDoc(collection(db, 'Directory_Settings'), newDoc);
			setData((prevData) => [...prevData, { ...newDoc, id: newDocRef.id }].sort((a, b) => a.Order - b.Order));
			setNewField({
				Label: '',
				Field_Key: '',
				FieldType: 'STRING',
				Order: '',
				Active: false,
				Public: false,
				Editable_By_User: false,
				Editable_By_Admin: false,
				DropDownChoices: [],
			});
			setAlert('Successfully added new field.', 'success');
		} catch (error) {
			setAlert(`Error adding new field: ${error}`, 'error');
		}
		setUpdatingData(false);
		setUnsavedChanges(false);
	};

	// Handle adding a dropdown choice
	const handleAddDropDownChoice = (id) => {
		setData((prevData) =>
			prevData.map((item) => {
				if (item.id === id) {
					return {
						...item,
						DropDownChoices: [...(item.DropDownChoices || []), ''],
					};
				}
				return item;
			})
		);
		setUnsavedChanges(true);
	};

	// Handle dropdown choice change
	const handleDropDownChoiceChange = (value, id, choiceIndex) => {
		setData((prevData) =>
			prevData.map((item) => {
				if (item.id === id) {
					const updatedChoices = [...(item.DropDownChoices || [])];
					updatedChoices[choiceIndex] = value;
					return { ...item, DropDownChoices: updatedChoices };
				}
				return item;
			})
		);
		setUnsavedChanges(true);
	};

	// Handle deleting a dropdown choice
	const handleDeleteDropDownChoice = (id, choiceIndex) => {
		setData((prevData) =>
			prevData.map((item) => {
				if (item.id === id) {
					const updatedChoices = [...(item.DropDownChoices || [])];
					updatedChoices.splice(choiceIndex, 1);
					return { ...item, DropDownChoices: updatedChoices };
				}
				return item;
			})
		);
		setUnsavedChanges(true);
	};

	// // Delete a row
	// const handleDeleteRow = async (id) => {
	// 	setUpdatingData(true);
	// 	try {
	// 		await deleteDoc(doc(db, 'Directory_Settings', id));
	// 		setData((prevData) =>
	// 			prevData.filter((item) => item.id !== id).map((item, idx) => ({ ...item, Order: idx + 1 }))
	// 		);
	// 		setAlert('Successfully deleted row.', 'success');
	// 	} catch (error) {
	// 		setAlert(`Error deleting row: ${error}`, 'error');
	// 	}
	// 	setUpdatingData(false);
	// };

	// Save changes to Firestore
	const handleSaveData = async () => {
		setUpdatingData(true);
		const batch = writeBatch(db);
		data.forEach((item) => {
			const ref = doc(db, 'Directory_Settings', item.id);
			const { id, ...dataToUpdate } = item; // Remove `id` before updating Firestore
			batch.update(ref, dataToUpdate);
		});
		try {
			await batch.commit();
			setAlert('Changes saved successfully.', 'success');
		} catch (error) {
			setAlert(`Error saving data: ${error}`, 'error');
		}
		setUpdatingData(false);
		setUnsavedChanges(false);
	};

	// Helper function to set alert message
	const setAlert = React.useCallback(
		(message, severity) => {
			alertCtx.setMessage(message);
			alertCtx.setSeverity(severity);
			alertCtx.setActive(true);
			if (severity === 'success') alertCtx.setTimer(5000);
		},
		[alertCtx]
	);

	const columns = useMemo(
		() => [
			{
				accessorKey: 'Order',
				header: 'Order',
				Cell: ({ row }) => {
					const currentOrder = row.Order;
					const rowId = row.id;
					const adjacentRowUp = data.find((item) => item.Order === currentOrder - 1);
					const adjacentRowDown = data.find((item) => item.Order === currentOrder + 1);
					return (
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<Typography variant='body2' style={{ marginRight: '10px' }}>
								{currentOrder}
							</Typography>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<IconButton
									aria-label='up'
									onClick={() => {
										if (currentOrder > 1) {
											handleOrderChange(currentOrder - 1, rowId, adjacentRowUp.id, currentOrder);
										}
									}}
									disabled={updatingData}
								>
									<ArrowUpwardRoundedIcon fontSize='small' />
								</IconButton>
								<IconButton
									aria-label='down'
									onClick={() => {
										if (currentOrder < data.length) {
											handleOrderChange(currentOrder + 1, rowId, adjacentRowDown.id, currentOrder);
										}
									}}
									disabled={updatingData}
								>
									<ArrowDownwardRoundedIcon fontSize='small' />
								</IconButton>
							</div>
						</div>
					);
				},
			},
			{
				accessorKey: 'Label',
				header: 'Label',
				Cell: ({ row }) => (
					<TextField
						value={row.Label}
						onChange={(e) => handleFormChange(e.target.value, row.id, 'Label')}
						disabled={updatingData}
					/>
				),
			},
			{
				accessorKey: 'FieldType',
				header: 'Field Type',
				Cell: ({ row }) => (
					<Box minWidth={150}>
						<Select
							fullWidth
							value={row.FieldType}
							onChange={(e) => handleFormChange(e.target.value, row.id, 'FieldType')}
							disabled={updatingData}
						>
							<MenuItem value={'BOOL'}>Boolean</MenuItem>
							<MenuItem value={'DATE'}>Date</MenuItem>
							<MenuItem value={'SELECTION'}>Selection</MenuItem>
							<MenuItem value={'PHONE'}>Phone Number</MenuItem>
							<MenuItem value={'STRING'}>Text</MenuItem>
						</Select>
					</Box>
				),
			},
			{
				accessorKey: 'Active',
				header: 'Active',
				Cell: ({ row }) => (
					<Checkbox
						checked={row.Active}
						onChange={(e) => handleFormChange(e.target.checked, row.id, 'Active')}
						disabled={updatingData}
					/>
				),
			},
			{
				accessorKey: 'Public',
				header: 'Public',
				Cell: ({ row }) => (
					<Checkbox
						checked={row.Public || false}
						onChange={(e) => handleFormChange(e.target.checked, row.id, 'Public')}
						disabled={updatingData}
					/>
				),
			},
			{
				accessorKey: 'Editable_By_User',
				header: 'Editable By User',
				Cell: ({ row }) => (
					<Checkbox
						checked={row.Editable_By_User}
						onChange={(e) => handleFormChange(e.target.checked, row.id, 'Editable_By_User')}
						disabled={updatingData}
					/>
				),
			},
			{
				accessorKey: 'Editable_By_Admin',
				header: 'Editable By Admin',
				Cell: ({ row }) => (
					<Checkbox
						checked={row.Editable_By_Admin}
						onChange={(e) => handleFormChange(e.target.checked, row.id, 'Editable_By_Admin')}
						disabled={updatingData}
					/>
				),
			},
		],
		[data, handleFormChange, handleOrderChange, updatingData]
	);

	const visibleColumns = columns.filter((column) => !['id', 'Field_Key'].includes(column.accessorKey));

	if (loading) return <LoadingSpinner />;

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Button
					variant='contained'
					color='primary'
					startIcon={<AddCircleRoundedIcon />}
					onClick={handleAddRow}
					disabled={updatingData}
				>
					Add Row
				</Button>
				<Button
					variant='contained'
					color='secondary'
					startIcon={<SaveRoundedIcon />}
					onClick={handleSaveData}
					disabled={updatingData || !unsavedChanges}
					style={{ marginLeft: '10px' }}
				>
					Save Changes
				</Button>
			</Grid>
			<Grid item xs={12}>
				<TableContainer component={Paper} sx={{ maxHeight: 800 }}>
					<Table stickyHeader aria-label='directory settings table'>
						<TableHead>
							<TableRow>
								<TableCell />
								{/* Render all visible column headers */}
								{visibleColumns.map((column) => (
									<TableCell key={column.accessorKey}>{column.header}</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{/* Render the rows, including expanding functionality */}
							{data.map((row) => (
								<React.Fragment key={row.id}>
									<TableRow hover>
										<TableCell>
											{row.FieldType === 'SELECTION' ? (
												<IconButton
													onClick={() =>
														setExpandedRows((prev) => ({
															...prev,
															[row.id]: !prev[row.id],
														}))
													}
												>
													{expandedRows[row.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
												</IconButton>
											) : null}
										</TableCell>
										{visibleColumns.map((column) => (
											<TableCell key={column.accessorKey}>
												{column.Cell ? column.Cell({ row }) : row[column.accessorKey]}
											</TableCell>
										))}
									</TableRow>

									{/* Expandable section for DropDownChoices */}
									{row.FieldType === 'SELECTION' && row.DropDownChoices && (
										<TableRow>
											<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={visibleColumns.length + 1}>
												<Collapse in={expandedRows[row.id]} timeout='auto' unmountOnExit>
													<Grid container spacing={1} style={{ padding: '10px' }}>
														<Grid item xs={12}>
															<Typography fontWeight={'bold'} variant='h5' textAlign={'center'}>
																Drop Down Choices
															</Typography>
														</Grid>
														{row.DropDownChoices.map((choice, choiceIndex) => (
															<Grid item xs={12} key={choiceIndex} style={{ display: 'flex', alignItems: 'center' }}>
																<TextField
																	value={choice}
																	onChange={(e) => handleDropDownChoiceChange(e.target.value, row.id, choiceIndex)}
																	disabled={updatingData}
																	style={{ marginRight: '5px' }}
																/>
															</Grid>
														))}
														<Grid item xs={12}>
															<Button
																variant='outlined'
																onClick={() => handleAddDropDownChoice(row.id)}
																disabled={updatingData}
															>
																Add Choice
															</Button>
														</Grid>
													</Grid>
												</Collapse>
											</TableCell>
										</TableRow>
									)}
								</React.Fragment>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</Grid>
	);
}

export default DirectorySettings;
