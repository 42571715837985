import React, { useEffect, useState, Fragment, useMemo, useContext } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { Box, Typography } from '@mui/material';

import PageCards from '../components/ui/PageCards';
import AlertContext from '../components/ui/AlertContext';
import MRT_TABLE from '../components/ui/MRT_TABLE';

export default function UniqueIDs() {
	const alertCtx = useContext(AlertContext);
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState(null);

	// Table columns
	const columns = useMemo(
		() => [
			{
				id: 'name',
				accessorFn: (row) => `${row.Preferred_Name} ${row.Last_Name}`,
				header: 'Name',
			},
			{
				id: 'EPT_Email',
				accessorKey: 'EPT_Email',
				header: 'EPT Email',
			},
			{
				id: 'Unique_ID',
				accessorKey: 'Unique_ID',
				header: 'Unique ID',
			},
		],
		[]
	);

	// Fetch data from Firestore
	useEffect(() => {
		const getData = async () => {
			setIsLoading(true);
			const functions = getFunctions();
			const Directory_Get_Users_Field = httpsCallable(functions, 'Directory_Get_Users_Field');
			const result = await Directory_Get_Users_Field({ FIELD: 'Unique_ID' });
			console.log(result);
			if (result.data.code === 200) setData(result.data.data);
			else {
				alertCtx.setSeverity(result.data.status.toLowerCase());
				alertCtx.setMessage(result.data.message);
				alertCtx.setActive(true);
			}
			setIsLoading(false);
		};

		if (!data) getData();
	}, []);

	return (
		<Fragment>
			<PageCards>
				<Typography variant='h3' color='primary' mb={2} textAlign={'center'}>
					Unique IDs
				</Typography>

				<Box sx={{ display: 'flex', justifyContent: 'center' }} mb={2}>
					<Typography variant='subtitle1' textAlign='center' sx={{ maxWidth: '70%' }}>
						The IDs below are unique and are guaranteed to not be represented elsewhere. These IDs should be used to
						blind a provider when referring to them about sensitive information in documents and minutes.
					</Typography>
				</Box>

				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<MRT_TABLE
						columns={columns}
						data={data || []}
						loading={isLoading}
						pageSize={20}
						sortBy={[{ Id: 'name', Direction: 'asc', TrueFalse: true }]}
						maxHeight={'65vh'}
						hiddenColumns={['EPT_Email']}
					/>
				</Box>
			</PageCards>
			<br />
		</Fragment>
	);
}
